/*
    Name: login.scss
    Description: Customize the user web interface 
*/

#login {
    background: linear-gradient(to bottom, #00aff0 30%, #e2e2e2 0) repeat-x #e2e2e2;
    width: 100%;
    display: inline-table;
    overflow-x: visible;

    .bg-member-login {
        background: url(../images/bg_user_login.png) center top no-repeat;
        background-size: 794px 538px; //original size
        font-size: 12px;
        min-height: 538px;
    }

    .login-box {
        div > label {
            font-size: 13px;
            color: #007dc5;
            padding-right: 5px;
            font-weight: bold;
        }
    }
}

#register {
    .btn-register {
        padding-top: 100px;
        padding-left: 80px;
    }
}

#login {
    #logo {
        margin-top: 60px;
        margin-left: 10px;
    }
    .login-box {
        #btn-login {
            margin-right: 0px;
        }
    }
}

/* Login */

.arrow-active{
    background: url(../images/arrowActive.png) center top no-repeat;
    background-repeat: no-repeat;  
    width: 195px;
    height: 60px;

    text-align: center;
    color: #fff;    
    font-weight: bolder;
    z-index: 1;
    
    display: inline-block;    
    position: relative;
    padding-top: 6px;
    padding-right: 12px;

    margin-right: -40px;
}

.arrow {
    background: url(../images/arrow.png) center top no-repeat;
    background-repeat: no-repeat;  
    width: 195px;
    height: 60px;

    text-align: center;
    color:rgb(90,136,159);    
    font-weight: bolder;
    z-index: 1;
    
    display: inline-block;    
    position: relative;
    padding-top: 6px;
    padding-right: 12px;
  
    margin-right: -40px;
}

.blueSolidBorderBox {
    border: 2px solid #cbe5ee;
    padding: 10px;
    margin: 20px 0 0 0;
}

.tblRegister th, .tblRegister2 th {
    padding: 6px;
    font-weight: bold;
    background: #e9e9e9;
    text-align: left;
}