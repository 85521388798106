/****************** ADMIN PANEL ***************/

.esi-card {
    border: 2px solid #cbe5ee;
    .esi-html-container {
        border: 1px solid #E4E4E4;
        padding: 20px;
        background-color: #F6F6F6;
    }
    .esi_blockheader {
        font-weight: 700;
        display: block;
        text-transform: uppercase;
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 16px;
    }
}

.esi-card-grey {
    max-width: 750px;
    margin: 50px auto;
    padding: 15px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    -moz-box-shadow: 0px 0px 0px 3px #ccc;
    -webkit-box-shadow: 0px 0px 0px 3px #ccc;
    box-shadow: 0px 0px 0px 3px #ccc;
    text-align: center;
    background-color: white;
}

.esi-card-header {
    background-color: #fff;
    border-bottom: 1px solid #cbe5ee;
    font: bold 18px Arial;
    color: #0eadd9;
}


/* ESI CARD WRITING*/

.esi-card-writing {
    border: 2px solid #D2E0EB;
    .card-title {
        font-size: 14px;
    }
    .card-body {
        background-color: #F6FBFD;
    }
}

.esi-card-header-writing {
    background-color: #D2E0EB;
    border-color: #D2E0EB;
    color: #47749A;
    font-weight: 700;
    font-size: 11px;
}

.esi-box {
    .esi-table {
        tr {
            th {
                background-color: #ffffff;
            }
            th,
            td {
                font-size: 11px;
                text-align: center;
            }
        }
    }
}

.esi-tab-container {
    .ui-widget.ui-widget-content {
        border: 0px !important;
        background-color: transparent !important;
        .ui-tabs-nav {
            a {
                font-size: 12px;
            }
        }
        .ui-tabs-panel {
            a {
                color: #c60000;
            }
            a:hover {
                color: #6e0000
            }
        }
    }
}

.esi-tabs {
    .ui-tabs-nav {
        margin: 0px !important;
        padding: 0px !important;
    }
    .ui-widget-header {
        background-color: transparent !important;
        border: none;
        border-bottom: 0px;
    }
    .ui-tabs-panel {
        border-width: 1px !important;
        border: 1px solid #D2E0EB;
        background-color: #fff !important;
        padding: 18px;
        margin: 0px;
    }
    .ui-tabs-tab {
        border-top: 1px solid #d2e0eb !important;
        border-left: 1px solid #d2e0eb !important;
        border-right: 1px solid #d2e0eb !important;
        background-color: #D2E0EB !important;
    }
    .ui-tabs-active {
        border-top: 1px solid #d2e0eb !important;
        border-left: 1px solid #d2e0eb !important;
        border-right: 1px solid #d2e0eb !important;
        border-bottom: 0px;
        background-color: #fff !important;
        a {
            color: #000 !important;
            font-weight: bold
        }
    }
}


/*background*/

.bg-gray {
    background-color: #e2e2e2;
}

.grayBackgroundBox {
    background: url(../images/bgGray.gif);
    padding: 10px;
}

.grayBox {
    border: 2px solid #eeeeee;
    padding: 10px;
    margin: 20px 0 0 0;
}

.submenu.nav {
    text-decoration: none;
    font: bold 12px Arial;
    line-height: 6px;
    color: #1e1e1e;
    padding: 0 15px;
    background: #009fd9;
    /* height: 25px; */
    padding: 6px 0 0 4px;
    /* margin: -4px -20px 0 -20px; */
    list-style: none;
    border-bottom: 2px solid #eee57d;
    .active {
        background-color: #eee57d;
        color: #1e1e1e;
    }
}


/*scrollable table */

.scrollable-x {
    overflow-x: auto;
}


/* LESSSON MODULE */


/*class*/

.class-schedule {
    font-size: 12px;
    vertical-align: top;
}


/*checkbox*/

.checkbox-options {
    font-size: 12px;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    li {
        padding-top: 2px;
        ul {
            padding-left: 30px;
            padding-top: 4px;
        }
    }
}


/*flags*/

.flag-ph {
    background-image: url("/images/flag-ph.jpg");
    background-repeat: no-repeat;
    width: 16px;
    height: 12px;
    display: inline-block;
}

.flag-jp {
    background-image: url("/images/flag-jp.jpg");
    background-repeat: no-repeat;
    width: 16px;
    height: 12px;
    display: inline-block;
}


/*custom card */

.card label {
    font-size: 12px;
}


/*custom table */

.esi-table {
    border-collapse: separate;
    border-spacing: 2px;
    margin-bottom: 0px;
    white-space: normal;
    thead {
        tr {
            th {
                padding: 2px 5px 2px;
                min-width: 45px;
                min-height: 29px;
            }
            th::before,
            th::after {
                top: 1px;
            }
            td {
                background-color: #d0e8f7;
                border-bottom: 3px solid #7dbae0;
                text-align: center;
                min-width: 45px;
                min-height: 29px;
                padding: .25rem;
            }
        }
    }
    tbody {
        td {
            font-size: 12px;
            text-align: center;
            border: 0px;
            border-right: 1px solid #d0e8f7;
            vertical-align: top;
            padding: 5px 0px 5px;
            min-width: 45px;
            min-height: 29px;
        }
    }
}

.table-schedules {
    td {
        min-width: 80px;
        min-height: 29px;
        background: url(../images/bgSchedule.gif) repeat scroll 0 0 transparent;
        font: 12px Arial;
        text-align: center;
    }
}

.legend {
    table {
        white-space: nowrap;
    }
    font-size: 12px;
    div {
        font-size: 12px;
    }
    td {
        font-size: 12px;
        width: 25px;
    }
}

.esi-card-header-title {
    background: #0076be;
    font: bold 18px Arial;
    color: #ffffff;
    text-align: center;
}


/* user */

.blueBox {
    background: #d7f3fa;
    padding: 10px;
}

.blueBrokenLineBox {
    border: 2px dashed #b8e9fa;
    padding: 10px;
    margin: 10px 0 0 0;
}

.reservationTable {
    border: 2px solid #d3eff1;
    border-radius: 5px 5px;
    padding: 5px;
    width: 100%;
}

.reservationTable th {
    background-color: #caf3f5;
    padding: 7px 0;
    font-size: 12px;
    text-align: center;
}

.reservationTable th.heading {
    background: #8bdade;
    font: bold 14px Arial;
}

.reservationTable td {
    background: #fff;
    font: bold 14px Arial;
}

a.blue {
    font: normal 12px Arial;
    color: #01abdd;
    text-decoration: none;
}

a.blue:hover {
    font: normal 12px Arial;
    color: #006684;
    text-decoration: none;
}

a.red {
    font: normal 12px Arial;
    color: #e9333f;
    text-decoration: none;
}

a.red:hover {
    font: normal 12px Arial;
    color: #7b0009;
    text-decoration: none;
}

//hover displayer for schedules (tutor)
.toggleHide:hover .hide {
    background-color: #fff;
    display: block;
}


/* old toggle without a send a message button
.toggleHide .hide {
    display: none;
    border: 1px solid #d0cbcb;
    background-color: #fff;
    position: relative;
    top: -34px;
    left: 34px;
    width: 87px;
    padding: 3px;
}
*/

.toggleHide .hide {
    display: none;
    border: 1px solid #d0cbcb;
    background-color: #fff;
    position: relative;
    top: -55px;
    left: 0px;
    width: 170px;
    padding: 8px;
}

//write report
.toggleWriteReport:hover .hide {
    background-color: #fff;
    display: block;
}

.toggleWriteReport .hide {
    display: none;
    border: 1px solid #d0cbcb;
    background-color: #fff;
    width: 87px;
    padding: 3px;
}

.btn-pink {
    background: #d40274;
    border: 1px solid #a8015c;
    color: #ffffff;
    padding: 3px 5px;
    cursor: pointer;
}


/*tooltip*/

.comment_tooltip {
    position: relative;
    display: inline-block;
}

.comment_tooltip .comment_tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: #fff;
    color: #c60000;
    text-align: center;
    border-radius: 6px;
    padding: 6px 0;
    font-size: 12px;
    /* Position the comment_tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    overflow: hidden;
    border: 1px solid rgb(211, 211, 211);
    z-index: 9999;
}

.comment_tooltip:hover .comment_tooltiptext {
    visibility: visible;
}

.comment_tooltip a:hover .comment_tooltiptext {
    font-size: 12px;
    visibility: visible;
}

.blink {
    animation: blink 1s;
    -webkit-animation: blink 1s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: blink 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation: blink 1s;
    -o-animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.breadcrumb-item.active a p,
.breadcrumb-item.active p {
    margin-top: 0 !important;
    display: inline-table !important;
}


/* top urgent notification  */
.top-urgent-notification {
    cursor: pointer;
    float: right;
    top: -28px;
    right: 0;
    position: relative;
    text-align: right;
    height: 0px;
}

.position-bottom-left {
    position: fixed;
    bottom: 0px;
    left: auto;
    z-index: 9999;
}



/* Blinking animation */
@keyframes blink {
    50% { opacity: 0; }
}

.blinking-badge {
    animation: blink 1s infinite;
    padding: 3px 15px 3px;
    width: 280px;
    margin-bottom: 12px;
}

/* custom text */
.text-underline {
    text-decoration: underline;
}